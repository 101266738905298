import React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import WarningIcon from '@material-ui/icons/Warning';
import { withSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

// const styles1 = theme => ({
//   success: {
//     backgroundColor: green[600],
//   },
//   error: {
//     backgroundColor: theme.palette.error.dark,
//   },
//   info: {
//     backgroundColor: theme.palette.primary.dark,
//   },
//   warning: {
//     backgroundColor: amber[700],
//   },
//   icon: {
//     fontSize: 20,
//   },
//   iconVariant: {
//     opacity: 0.9,
//     marginRight: theme.spacing(1),
//   },
//   message: {
//     display: 'flex',
//     alignItems: 'center',
//   },
// });

export const SnackBar = {
  success: '',
  info: '',
  warning: '',
  error: '',
  default: '',
};

// function MySnackbarContent(props) {
//   const { classes, className, message, onClose, variant, ...other } = props;
//   const Icon = variantIcon[variant];

//   return (
//     <SnackbarContent
//       className={classNames(classes[variant], className)}
//       aria-describedby="client-snackbar"
//       message={
//         <span id="client-snackbar" className={classes.message}>
//           <Icon className={classNames(classes.icon, classes.iconVariant)} />
//           {message}
//         </span>
//       }
//       action={[
//         <IconButton key="close" aria-label="Close" color="inherit" className={classes.close} onClick={onClose}>
//           <CloseIcon className={classes.icon} />
//         </IconButton>,
//       ]}
//       {...other}
//     />
//   );
// }

// MySnackbarContent.propTypes = {
//   classes: PropTypes.object.isRequired,
//   className: PropTypes.string,
//   message: PropTypes.node,
//   onClose: PropTypes.func,
//   variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
// };

class CustomizedSnackbars extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      variant: '',
      message: '',
      queue: [],
    };

    SnackBar.success = this.success;
    SnackBar.info = this.info;
    SnackBar.warning = this.warning;
    SnackBar.error = this.error;
    SnackBar.default = this.default;
  }

  handleClick = () => {
    this.setState({ open: true });
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ open: false });
  };

  // add multiple actions to one snackbar
  action = key => (
    <IconButton
      onClick={() => {
        this.props.closeSnackbar(key);
      }}
    >
      <CloseIcon style={{ color: '#FFFFFF' }} />
    </IconButton>
  );

  default = message => {
    this.props.enqueueSnackbar(message, { action: this.action });
  };
  success = message => {
    this.props.enqueueSnackbar(message, { variant: 'success', action: this.action });
  };
  info = message => {
    this.props.enqueueSnackbar(message, { variant: 'info', action: this.action });
  };
  warning = message => {
    this.props.enqueueSnackbar(message, { variant: 'warning', action: this.action });
  };
  error = message => {
    this.props.enqueueSnackbar(message, { variant: 'error', action: this.action });
  };
  render() {
    // const { classes } = this.props;

    return (
      <div>
        {/* <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.open}
          //   autoHideDuration={3000}
          onClose={this.handleClose}
        >
          {this.state.queue.map((item, index) => (
            <MySnackbarContentWrapper
              className={classes.margin}
              onClose={this.handleClose}
              variant={item.variant}
              message={item.message}
            />
          ))}
        </Snackbar> */}
      </div>
    );
  }
}

export default withSnackbar(CustomizedSnackbars);
