import React, { Component } from 'react';
import THInput from '../../shared/components/input/input';
import THSelect from '../../shared/components/select/select';
import Request from '../../shared/logic/http/request';
import './client-details.scss';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import THSimpleDatePicker from '../../shared/components/simple-date-picker/simple-datepicker';
import Autocomplete from '../../shared/components/autocomplete/autocomplete';
class ClientDetails extends Component {
  state = {
    addressHistory: [],
  };

  async componentDidMount() {
    let candidateId = this.props.match.params.token;
    await this.getFullProfile(candidateId);
  }

  getIndustries = async () => {
    return await Request.get({ url: Request.requestMapping.util.industries });
  };

  getFunctions = async () => {
    return await Request.get({ url: Request.requestMapping.util.functions });
  };

  getCountries = async () => {
    return await Request.get({ url: Request.requestMapping.util.countries });
  };

  getStates = async () => {
    return await Request.get({ url: Request.requestMapping.util.states });
  };

  getFullProfile = async (candidateId) => {
    let { data, error } = await Request.get({ url: Request.requestMapping.candidate + '/' + candidateId });
    if (data) {
      let userData = await Request.get({
        url: `${Request.requestMapping.userInfo}/${candidateId}`,
      });
      if (userData && !userData.error) {
        this.setState({
          profileActivated: userData.data.active,
          userEmail:
            data.contactData[0] && data.contactData[0].email ? data.contactData[0].email : userData.data.username,
        });
      }
      let candidateName = data.personalInfo[0]
        ? data.personalInfo[0].firstName + ' ' + data.personalInfo[0].lastName
        : '';
      document.title = candidateName;
      this.setState({
        loading: false,
        candidate: data.candidate,
        personalInfo: data.personalInfo[0],
        professionalInfo: data.professionalInfo[0],
        companyInfo: data.companyData[0],
        contactInfo: data.contactData[0],

        selectedCountry:
          data.contactData && data.contactData[0] && data.contactData[0].country_code
            ? data.contactData[0].country_code
            : '',
      });
    }
  };

  render() {
    return (
      <div className="client-detail-wrapper">
        <h3 style={{ color: '#5ab2a5', fontSize: '26px', marginBottom: '16px', textTransform: 'uppercase' }}>
          {this.state.personalInfo
            ? `${this.state.personalInfo.firstName} ${this.state.personalInfo.lastName}`
            : 'LOADING PROFILE'}
        </h3>
        <div style={{ display: 'flex' }}>
          <span style={{ fontWeight: 'bold', fontSize: '20px', marginRight: '16px', lineHeight: '35px' }}>TH ID:</span>

          <span
            style={{
              // fontWeight: 'bold',
              color: '#5ab2a5',
              fontSize: '18px',
              marginRight: '16px',
              paddingLeft: '8px',
              width: '150px',
              backgroundColor: '#f3f3f3',
              border: '1px solid #8b8b8b',
              borderRadius: '0',
              lineHeight: '35px',
            }}
          >
            {this.state.candidate ? this.state.candidate.id : ''}
          </span>
        </div>
        <span style={{ fontWeight: 'bold', fontSize: '20px', marginRight: '16px', lineHeight: '35px' }}>Consent Date:</span>
        <span
            style={{
              // fontWeight: 'bold',
              color: '#5ab2a5',
              fontSize: '18px',
              marginRight: '16px',
              paddingLeft: '8px',
              paddingRight: '8px',
              width: '150px',
              backgroundColor: '#f3f3f3',
              border: '1px solid #8b8b8b',
              borderRadius: '0',
              lineHeight: '35px',
            }}
          >
              {this.state.candidate ? `${moment(this.state.candidate.consentDate).format('DD MMM YYYY')} ` : ''}
          </span>
        <div className="client-detail-container">
          <h3 className="section-heading" style={{ gridColumn: '1/4' }}>
            Personal Information
          </h3>
          <div>
            <div>
              <THInput
                // required
                disabled={!this.state.editLoginInfo}
                ref={(ref) => (this.emailRef = ref)}
                type="email"
                name={'email'}
                initialLoad={this.state.loading}
                label={() => <b style={{ fontSize: '15px', fontWeight: '600' }}>Email Address</b>}
                defaultValue={
                  this.state.contactInfo
                    ? this.state.contactInfo.email
                    : this.state.userEmail
                    ? this.state.userEmail
                    : ''
                }
              />
            </div>
            <div className="first-column-input">
              <THInput
                className="first-column-input"
                disabled={!this.state.editPersonalInfo}
                ref={(ref) => (this.first_name = ref)}
                type="text"
                name={'first_name'}
                label={() => <b style={{ fontSize: '15px', fontWeight: '600' }}>First name</b>}
                defaultValue={this.state.personalInfo ? this.state.personalInfo.firstName : ''}
                initialLoad={this.state.loading}
              />
            </div>
            <div className="first-column-input">
              <THInput
                disabled={!this.state.editPersonalInfo}
                ref={(ref) => (this.middle_name = ref)}
                type="text"
                name={'middle_name'}
                label={() => <b style={{ fontSize: '15px', fontWeight: '600' }}>Middle name</b>}
                defaultValue={this.state.personalInfo ? this.state.personalInfo.middleName : ''}
                initialLoad={this.state.loading}
              />
            </div>
            <div className="first-column-input">
              <THInput
                disabled={!this.state.editPersonalInfo}
                ref={(ref) => (this.last_name = ref)}
                type="text"
                className="first-column-input"
                name={'last_name'}
                label={() => <b style={{ fontSize: '15px', fontWeight: '600' }}>Last name</b>}
                defaultValue={this.state.personalInfo ? this.state.personalInfo.lastName : ''}
                initialLoad={this.state.loading}
              />
            </div>
          </div>
          <div>
            <div className="first-column-input">
              <THSelect
                disabled={!this.state.editPersonalInfo}
                ref={(ref) => (this.country_code = ref)}
                name={'country_code'}
                className="first-column-input"
                label={() => <b style={{ fontSize: '15px', fontWeight: '600' }}>Country</b>}
                note={''}
                dataSource={this.getCountries}
                handleChange={(value) => this.setState({ selectedCountry: value ? value.id : '' })}
                initialLoad={this.state.loading}
                displayName={'name'}
                comparisonValue="id"
                defaultValue={this.state.contactInfo ? this.state.contactInfo.country_code : ''}
              />
            </div>
            <div className="first-column-input">
              {this.state.selectedCountry && this.state.selectedCountry === 194 && (
                <THSelect
                  disabled={!this.state.editPersonalInfo}
                  ref={(ref) => (this.stateRef = ref)}
                  name={'state'}
                  className="first-column-input"
                  label={() => <b style={{ fontSize: '15px', fontWeight: '600' }}>State/Province</b>}
                  note={''}
                  dataSource={this.getStates}
                  displayName={'abbreviation'}
                  initialLoad={this.state.loading}
                  comparisonValue="abbreviation"
                  defaultValue={this.state.contactInfo ? this.state.contactInfo.state : ''}
                />
              )}

              {(this.state.selectedCountry === '' || this.state.selectedCountry !== 194) && (
                <THInput
                  disabled={!this.state.editPersonalInfo}
                  ref={(ref) => (this.state_input = ref)}
                  name={'state_input'}
                  className="first-column-input"
                  label={() => <b style={{ fontSize: '15px', fontWeight: '600' }}>State/Province</b>}
                  initialLoad={this.state.loading}
                  defaultValue={this.state.contactInfo ? this.state.contactInfo.state : ''}
                />
              )}
            </div>
            <div className="first-column-input">
              <THInput
                disabled={!this.state.editPersonalInfo}
                ref={(ref) => (this.city = ref)}
                type="text"
                className="first-column-input"
                name={'city'}
                label={() => <b style={{ fontSize: '15px', fontWeight: '600' }}>City/Town</b>}
                defaultValue={this.state.contactInfo ? this.state.contactInfo.city : ''}
                initialLoad={this.state.loading}
              />
            </div>
            <div className="second-column-input" style={{ gridRow: '6' }}>
              <THInput
                disabled={!this.state.editPersonalInfo}
                ref={(ref) => (this.zip = ref)}
                type="text"
                className="second-column-input"
                name={'zip'}
                initialLoad={this.state.loading}
                label={() => <b style={{ fontSize: '15px', fontWeight: '600' }}>ZIP/Postal code</b>}
                defaultValue={this.state.contactInfo ? this.state.contactInfo.zip : ''}
              />
            </div>
          </div>
          <div>
            {/* SELECT */}
            <div className="second-column-input" style={{ gridRow: '3' }}>
              <THSelect
                disabled={!this.state.editPersonalInfo}
                required
                ref={(ref) => (this.gender = ref)}
                className="second-column-input"
                name={'gender'}
                label={() => <b style={{ fontSize: '15px', fontWeight: '600' }}>Gender</b>}
                note={''}
                dataSource={() => ({
                  data: [
                    {
                      value: 1,
                      name: 'Male',
                    },
                    {
                      value: 2,
                      name: 'Female',
                    },
                    {
                      value: 3,
                      name: 'Other',
                    },
                    {
                      value: 0,
                      name: 'Prefer not to disclose',
                    },
                  ],
                })}
                initialLoad={this.state.loading}
                displayName={'name'}
                comparisonValue="value"
                defaultValue={this.state.personalInfo ? this.state.personalInfo.gender : ''}
              />
            </div>
            <div className="second-column-input" style={{ marginBottom: '0', gridRow: '4' }}>
              <THSimpleDatePicker
                noBottomMargin={true}
                disabled={!this.state.editPersonalInfo}
                className="second-column-input"
                ref={(ref) => (this.dob = ref)}
                name={'dob'}
                label={() => <b style={{ fontSize: '15px', fontWeight: '600' }}>Date of birth</b>}
                initialLoad={this.state.loading}
                defaultValue={
                  this.state.personalInfo && this.state.personalInfo.dob
                    ? moment(this.state.personalInfo.dob).format('YYYY-MM-DD')
                    : ''
                }
              ></THSimpleDatePicker>
            </div>
            <div className="second-column-input" style={{ gridRow: '5' }}>
              <THSelect
                disabled={!this.state.editPersonalInfo}
                ref={(ref) => (this.education = ref)}
                name={'education'}
                label={() => <b style={{ fontSize: '15px', fontWeight: '600' }}>Highest level of education</b>}
                note={''}
                dataSource={() => ({
                  data: [
                    {
                      value: 1,
                      name: 'Some high school, no diploma',
                    },
                    {
                      value: 2,
                      name: 'High school graduate, diploma, or GED',
                    },
                    {
                      value: 3,
                      name: 'Some college coursework, no degree',
                    },
                    {
                      value: 4,
                      name: 'Bachelor’s degree',
                    },
                    {
                      value: 5,
                      name: 'Master’s degree',
                    },
                    {
                      value: 6,
                      name: 'Doctorate degree',
                    },
                  ],
                })}
                initialLoad={this.state.loading}
                className="second-column-input"
                displayName={'name'}
                comparisonValue="value"
                defaultValue={this.state.personalInfo ? this.state.personalInfo.education : ''}
              />
            </div>
          </div>
        </div>
        <div className="client-detail-container">
          <div style={{ gridColumn: '1' }}>
            <div className="second-column-input" style={{ gridRow: '1' }}>
              <h3 className="section-heading">Company Information</h3>
            </div>
            <div>
              <THInput
                disabled={!this.state.editPersonalInfo}
                ref={(ref) => (this.zip = ref)}
                type="text"
                className="second-column-input"
                name={'zip'}
                initialLoad={this.state.loading}
                label={() => <b style={{ fontSize: '15px', fontWeight: '600' }}>Company name</b>}
                defaultValue={this.state.companyInfo ? this.state.companyInfo.company.name : ''}
              />
            </div>
            <div>
              <THSelect
                disabled={!this.state.editCompanyInfo}
                ref={(ref) => (this.company_industry = ref)}
                name={'company_industry'}
                comparisonValue={'id'}
                label={() => (
                  <b style={{ fontSize: '15px', fontWeight: '600' }}>Your current company's primary industry</b>
                )}
                note={''}
                dataSource={this.getIndustries}
                initialLoad={this.state.loading}
                displayName={'name'}
                defaultValue={
                  this.state.companyInfo && this.state.companyInfo.industry ? this.state.companyInfo.industry.id : null
                }
              />
            </div>
            <div>
              <THSelect
                disabled={!this.state.editCompanyInfo}
                ref={(ref) => (this.employeeRange = ref)}
                name={'company_industry'}
                label={() => (
                  <b style={{ fontSize: '15px', fontWeight: '600' }}>Number of full-time employees worldwide</b>
                )}
                note={''}
                dataSource={() => ({
                  data: [
                    { name: 'I am the only employee', value: [0, 1] },
                    { name: '2 to less than 5', value: [2, 5] },
                    { name: '5 to less than 10', value: [5, 10] },
                    { name: '10 to less than 25', value: [10, 25] },
                    { name: '25 to less than 50', value: [25, 50] },
                    { name: '50 to less than 100', value: [50, 100] },
                    { name: '100 to less than 250', value: [100, 250] },
                    { name: '250 to less than 500', value: [250, 500] },
                    { name: '500 to less than 1,000', value: [500, 1000] },
                    { name: '1,000 to less than 2,500', value: [1000, 2500] },
                    { name: '2,500 to less than 5,000', value: [2500, 5000] },
                    { name: '5,000 to less than 10,000', value: [5000, 10000] },
                    { name: '10,000 to less than 20,000', value: [10000, 20000] },
                    { name: '20,000 to less than 30,000', value: [20000, 30000] },
                    { name: '30,000 to less than 40,000', value: [30000, 40000] },
                    { name: '40,000 to less than 50,000', value: [40000, 50000] },
                    { name: 'More than 50,000', value: [50000, 5000000] },
                  ],
                })}
                initialLoad={this.state.loading}
                displayName={'name'}
                comparisonValue={'value[0]'}
                defaultValue={this.state.companyInfo ? this.state.companyInfo.employeeRangeLower : ''}
              />
            </div>
            <div>
              <THSelect
                disabled={!this.state.editCompanyInfo}
                ref={(ref) => (this.companyRevenue = ref)}
                name={'company_industry'}
                label={() => <b style={{ fontSize: '15px', fontWeight: '600' }}>Company revenue</b>}
                note={''}
                dataSource={() => ({
                  data: [
                    { name: 'Less than $500,000', value: [0, 5] },
                    { name: '$500,000 to less than $1 million', value: [5, 10] },
                    { name: '$1 million to less than $2 million', value: [10, 20] },
                    { name: '$2 million to less than $5 million', value: [20, 50] },
                    { name: '$5 million to less than $10 million', value: [50, 100] },
                    { name: '$10 million to less than $25 million', value: [100, 250] },
                    { name: '$25 million to less than $50 million', value: [250, 500] },
                    { name: '$50 million to less than $100 million', value: [500, 1000] },
                    { name: '$100 million to less than $250 million', value: [1000, 2500] },
                    { name: '$250 million to less than $500 million', value: [2500, 5000] },
                    { name: '$500 million to less than $1 billion', value: [5000, 10000] },
                    { name: '$1 billion to less than $2 billion', value: [10000, 20000] },
                    { name: '$2 billion to less than $3 billion', value: [20000, 30000] },
                    { name: '$3 billion to less than $5 billion', value: [30000, 50000] },
                    { name: '$5 billion to less than $10 billion', value: [50000, 1000000] },
                    { name: 'More than $10 billion', value: [100000, 500000] },
                  ],
                })}
                initialLoad={this.state.loading}
                displayName={'name'}
                comparisonValue={'value[0]'}
                defaultValue={this.state.companyInfo ? this.state.companyInfo.companyRevenueLower : ''}
              />
            </div>
          </div>
          <div style={{ gridColumn: '2' }}>
            <div className="second-column-input">
              <h3 className="section-heading">Professional Information</h3>
            </div>
            <div>
              <THSelect
                disabled={!this.state.editProfessionalInfo}
                ref={(ref) => (this.proffession = ref)}
                name={'industry'}
                label={() => <b style={{ fontSize: '15px', fontWeight: '600' }}>Function</b>}
                comparisonValue={'id'}
                dataSource={this.getFunctions}
                displayName={'name'}
                initialLoad={this.state.loading}
                defaultValue={this.state.professionalInfo ? this.state.professionalInfo.function : ''}
              />
            </div>
            <div>
              <THSelect
                disabled={!this.state.editProfessionalInfo}
                ref={(ref) => (this.seniority = ref)}
                name={'industry'}
                comparisonValue={'value'}
                label={() => <b style={{ fontSize: '15px', fontWeight: '600' }}>Seniority</b>}
                dataSource={() => ({
                  data: [
                    {
                      value: 1,
                      name: 'CEO, President, Chairman, Partner',
                    },
                    {
                      value: 2,
                      name: 'Other C-level Executive e.g. COO/CFO/CIO/CTO/CMO etc.',
                    },
                    {
                      value: 3,
                      name: 'Vice President e.g. VP/SVP/EVP',
                    },
                    {
                      value: 4,
                      name: 'Senior Director / Director',
                    },
                    {
                      value: 5,
                      name: 'Senior Manager / Manager',
                    },
                    {
                      value: 6,
                      name: 'Senior Professional / Expert',
                    },
                    {
                      value: 7,
                      name: 'Individual Contributor / Staff',
                    },
                    {
                      value: 8,
                      name: 'Intern / Entry Level',
                    },
                  ],
                })}
                displayName={'name'}
                initialLoad={this.state.loading}
                defaultValue={this.state.professionalInfo ? this.state.professionalInfo.seniority : ''}
              />
            </div>
            <div>
              <THSimpleDatePicker
                noBottomMargin={true}
                disabled={!this.state.editProfessionalInfo}
                ref={(ref) => (this.start_date = ref)}
                name={'start_date'}
                label={() => (
                  <b style={{ fontSize: '15px', fontWeight: '600' }}>
                    Start date of your engagement in your current role
                  </b>
                )}
                initialLoad={this.state.loading}
                defaultValue={
                  this.state.professionalInfo && this.state.professionalInfo.employmentStartDate
                    ? moment(this.state.professionalInfo.employmentStartDate).format('YYYY-MM-DD')
                    : ''
                }
              ></THSimpleDatePicker>
            </div>
            <div>
              <THInput
                disabled={!this.state.editProfessionalInfo}
                ref={(ref) => (this.job_title = ref)}
                name={'job_title'}
                label={() => <b style={{ fontSize: '15px', fontWeight: '600' }}>Job title</b>}
                initialLoad={this.state.loading}
                defaultValue={this.state.professionalInfo ? this.state.professionalInfo.jobTitle : ''}
              />
            </div>
          </div>
          <div style={{ gridColumn: '3' }}>
            <div className="second-column-input">
              <h3 className="section-heading">Contact Info</h3>
            </div>
            <div className="second-column-input" style={{ gridRow: '1' }}>
              <THInput
                disabled={!this.state.editPersonalInfo}
                ref={(ref) => (this.phone = ref)}
                type="tel"
                name={'phone'}
                label={() => <b style={{ fontSize: '15px', fontWeight: '600' }}>Phone number</b>}
                defaultValue={this.state.contactInfo ? this.state.contactInfo.phone : ''}
                initialLoad={this.state.loading}
              />
            </div>
            <div className="second-column-input" style={{ gridRow: '2' }}>
              <THInput
                disabled={!this.state.editPersonalInfo}
                ref={(ref) => (this.mobile = ref)}
                type="tel"
                className="second-column-input"
                name={'mobile'}
                label={() => <b style={{ fontSize: '15px', fontWeight: '600' }}>Mobile phone number</b>}
                defaultValue={this.state.contactInfo ? this.state.contactInfo.mobile : ''}
                initialLoad={this.state.loading}
              />
            </div>
            <b style={{ fontSize: '15px', fontWeight: '600' }}>Email Addresses</b>
            <div className="addresses-display" style={{ gridRow: '2' }}>
              <p>Not yet connected</p>
              {this.state.addressHistory.map((item) => (
                <p>{item}</p>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ClientDetails);
