import React from 'react';
import logo from './logo.svg';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import Cookies from 'js-cookie';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import ResponsiveDrawer from './shared/drawer';
import CustomizedSnackbars, { SnackBar } from './shared/components/snackbar/snackbar';
// import { AlertsProvider } from './shared/logic/context-providers/alerts-provider';
import './App.css';
import ClientsPage from './pages/client/clients';
import ClientDetails from './pages/client/client-details';

function App() {
  return (
    <Router>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <ResponsiveDrawer>
          <Switch>
            <Route path="/clients/:token" component={() => <ClientDetails />} />
            <Route
              exact
              path="/clients"
              component={() => (
                // <div className="content-wrapper">
                //   <div className="content">
                <ClientsPage />
                //   </div>
                // </div>
              )}
            />
          </Switch>
        </ResponsiveDrawer>
        <CustomizedSnackbars />
      </MuiPickersUtilsProvider>
    </Router>
  );
}
export default App;
