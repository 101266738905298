import React, { Component } from 'react';

import Downshift from 'downshift';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
// import MatInput from '../input/mat-input';
import TextField from '@material-ui/core/TextField';

import './autocomplete.scss';

const styles = theme => ({
  root: {
    flexGrow: 1,
    margin: '16px',
    padding: '16px',
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 1000,
    marginTop: theme.spacing(1),
    maxHeight: '100px',
    left: 0,
    right: 0,
    top: -30,
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  inputInput: {
    width: '100%',
    height: '100%',
    flexGrow: 1,
  },
  divider: {
    height: theme.spacing(2),
  },
  textField: {
    // marginLeft: theme.spacing.unit,
    // marginRight: theme.spacing.unit,
    width: '100%',
    marginTop: '8px',
  },
  dense: {
    marginTop: 8,
  },
  menu: {
    width: 200,
  },
});

class Autocomplete extends Component {
  ticket = 0;

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      suggestions: [],
      viewablesuggestions: [],
      inputValue: '',
      input: '',
      status: '',
      loading: false,
      changeHappened: false,
      selection: null,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.role !== this.props.role || prevProps.currentRole !== this.props.currentRole) {
      this.setComponentStatus();
    }
  }

  async componentDidMount() {
    let myStatus = 'enabled';

    if (this.props.resolveComponent) {
      myStatus = await this.props.resolveComponent({ visible: this.props.visible, enabled: this.props.enabled });
    }

    this.setState({ input: this.getDefaultViewValue(), status: myStatus });
  }

  getData = () => {
    if (!this.state.selection && !this.state.changeHappened) {
      return this.props.defaultValue;
    }
    return this.state.selection;
  };

  clearSelection = () => {
    this.setState({ selection: null, inputValue: '', input: '' });
  };

  getDecorIcon(type, end) {
    switch (type) {
      case 'search':
        return <Icon className="decor">search</Icon>;
      default:
        return null;
    }
  }

  setComponentStatus = async () => {
    if (this.props.resolveComponent) {
      let myStatus = await this.props.resolveComponent({ visible: this.props.visible, enabled: this.props.enabled });
      this.setState({ status: myStatus });
    }
  };

  requestSuggestions = async event => {
    let myTicket = ++this.ticket;

    setTimeout(async () => {
      if (myTicket !== this.ticket) {
        return;
      }

      const newinputValue = ('' + event).toLowerCase();
      if (newinputValue.includes(this.state.inputValue) && this.state.inputValue.length > 0) {
        let filteredSuggs = this.state.suggestions.filter(sugg => {
          if (this.props.filterField) {
            if (_.isFunction(this.props.filterField)) {
              return this.props.filterField(sugg, newinputValue);
            } else {
              return ('' + _.get(sugg, this.props.filterField)).toLowerCase().includes(newinputValue.toLowerCase());
            }
          } else {
            return ('' + _.get(sugg, this.props.displayField)).toLowerCase().includes(newinputValue.toLowerCase());
          }
        });
        this.setState({
          inputValue: newinputValue,
          suggestions: filteredSuggs,
          viewablesuggestions: filteredSuggs.slice(0, 20 < filteredSuggs.length ? 20 : filteredSuggs.length),
        });
      } else if (newinputValue.length >= 1) {
        let newSuggs = await this.props.dataFetcher(newinputValue);
        debugger
        this.setState({
          inputValue: newinputValue,
          suggestions: newSuggs,
          viewablesuggestions: newSuggs.slice(0, 20 < newSuggs.length ? 20 : newSuggs.length),
        });
      }
    }, 200);
  };
  renderInput = inputProps => {
    const { InputProps, classes, ref, ...other } = inputProps;
    return (
      <TextField
        name={this.props.name}
        label={this.props.placeholder}
        className={'text-field'}
        disabled={this.props.disabled || this.props.readonly}
        placeholder={this.props.placeholder ? this.props.placeholder : '...'}
        // disabled={this.state.status && this.state.status === 'disabled'}
        margin="normal"
        variant={this.props.variant ? this.props.variant : 'outlined'}
        helperText={this.props.helpText}
        InputProps={{
          inputRef: ref,
          startAdornment: this.getDecorIcon(this.props.startDecorType),
          endAdornment: this.getDecorIcon(this.props.endDecorType),
          // classes: {
          //   root: classes.inputRoot,
          //   input: classes.inputInput,
          // },
          ...InputProps,
        }}
        {...other}
      />
    );
  };

  renderSuggestion = ({ suggestion, displayField, index, itemProps, highlightedIndex, selectedItem }) => {
    const isHighlighted = highlightedIndex === index;
    const isSelected = ((selectedItem && selectedItem.id) || '') === suggestion.id;
    let keyVal = _.isFunction(this.props.displayField)
      ? this.props.displayField(suggestion)
      : _.get(suggestion, this.props.displayField);
    return (
      <MenuItem
        {...itemProps}
        key={keyVal + index}
        selected={isHighlighted}
        component="div"
        style={{
          fontWeight: isSelected ? 500 : 400,
        }}
      >
        {keyVal}
      </MenuItem>
    );
  };

  handleScroll = e => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      let newSuggs = this.state.suggestions.slice(0, this.state.viewablesuggestions.length + 20);
      this.setState({
        viewablesuggestions: newSuggs,
      });
    }
  };

  getDefaultViewValue = () => {
    if (this.props.defaultValue) {
      let val = `${
        this.props.defaultValue
          ? _.isFunction(this.props.displayField)
            ? this.props.displayField(this.props.defaultValue)
            : _.get(this.props.defaultValue, this.props.displayField)
          : ''
      }`;
      return val;
    } else {
      return '';
    }
  };

  render = () => {
    const plchold = this.props.placeholder;
    return (
      <React.Fragment>
        {this.state.status && this.state.status !== 'hidden' && (
          <div className="autocomplete-root">
            <Downshift
              id="downshift-simple"
              inputValue={this.state.input}
              onInputValueChange={event => {
                let change = false;
                if (event === '') {
                  change = true;
                }
                this.setState({ changeHappened: this.state.changeHappened || change, input: event });
                this.requestSuggestions(event);
              }}
              onChange={selection => {
                if (this.props.passSelection) {
                  this.props.passSelection(selection);
                }
                this.setState({ selection: selection, changeHappened: true });
              }}
              itemToString={item =>
                `${
                  item
                    ? _.isFunction(this.props.displayField)
                      ? this.props.displayField(item)
                      : _.get(item, this.props.displayField)
                    : ''
                }`
              }
            >
              {({ getInputProps, getItemProps, getMenuProps, highlightedIndex, inputValue, isOpen, selectedItem }) => (
                <div className="container">
                  {this.renderInput({
                    fullWidth: true,
                    classes: styles,
                    InputProps: getInputProps({
                      placeholder: plchold ? plchold : 'Unesite šifru računa',
                    }),
                  })}
                  <div {...getMenuProps()}>
                    {isOpen && (
                      <Paper className="paper" onScroll={this.handleScroll}>
                        {this.state.viewablesuggestions.map((suggestion, index) => {
                          return this.renderSuggestion({
                            suggestion,
                            displayField: this.props.displayField,
                            index: index,
                            itemProps: getItemProps({ item: suggestion }),
                            highlightedIndex,
                            selectedItem,
                          });
                        })}
                      </Paper>
                    )}
                  </div>
                </div>
              )}
            </Downshift>
          </div>
        )}
      </React.Fragment>
    );
  };
}

Autocomplete.propTypes = {
  placeholder: PropTypes.string,
  displayField: PropTypes.string,
  filterField: PropTypes.string,
  dataFetcher: PropTypes.func,
  passSelection: PropTypes.func,
  //   getSuggestions: PropTypes.func,
};

export default withStyles(styles)(Autocomplete);
