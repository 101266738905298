import React from 'react';
import { withStyles } from '@material-ui/core/styles';
// import { withAlerts } from '../../shared/logic/context-providers/alerts-provider';
import { withRole } from '../../shared/logic/context-providers/role-provider';
import _ from 'lodash';
import CandidatesHandler from '../../shared/classes/users';
import Request from '../../shared/logic/http/request';
import Autocomplete from '../../shared/components/autocomplete/autocomplete_old';
import { Button } from '@material-ui/core';
import { CSVLink } from 'react-csv';
import THTable from '../../shared/components/th-table/table';
import THInput from '../../shared/components/input/input';
import THSelect from '../../shared/components/select/select';
import THMultiSelect from '../../shared/components/select/multiselect.js';
import THSimpleDatePicker from '../../shared/components/simple-date-picker/simple-datepicker';
import moment from 'moment';

let years = [];
for (let i = moment().year(); i >= 1900; i--) {
  years.push({ value: i, name: i });
}

const styles = (theme) => ({
  root: {
    // ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    // display: 'grid',
    // gridTemplateColumns: ' 1fr 4fr',
    // gridGap: '30px',
    borderRadius: '20px',
    marginTop: '20px',
  },
});

class ClientsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 50,
      page: 0,
      csvData: [],
      users: [],
      segments: [],
      companies: [],
      relevantUser: null,
      labelWidth: 0,
      jobTitleString: '',
      countries: [],
      americanStates: [],
      functions: [],
      jobTitles: [],
      industries: [],
      employmentStatus: [],
      firstName: '',
      lastName: '',
      middleName: '',
      email: '',
      name: '',
      company: '',
      gender: '',
      dobFrom: null,
      dobTo: null,
      jobStartDate: null,
      employeeRangeFrom: '',
      employeeRangeTo: '',
      revenueRangeFrom: '',
      revenueRangeTo: '',
      industry: '',
      function: '',
      city: '',
      zip: '',
      education: '',
      state: '',
      seniority: '',
      jobTitle: '',
      country: '',
      companyName: '',
    };
  }
  inputLabel = React.createRef();

  dataDefs = [
    'id',
    'consentDate',
    'firstName',
    'lastName',
    (row) =>
      row.country_code
        ? this.state.countries.find((item) => item.id === row.country_code)
          ? this.state.countries.find((item) => item.id === row.country_code).name
          : 'N/A'
        : 'N/A',
    (row) =>
      row.function
        ? this.state.functions.find((item) => item.id === row.function)
          ? this.state.functions.find((item) => item.id === row.function).name
          : 'N/A'
        : 'N/A',
    (row) =>
      row.seniority
        ? this.seniority.find((item) => item.value === row.seniority)
          ? this.seniority.find((item) => item.value === row.seniority).name
          : 'N/A'
        : 'N/A',
    'company',
    'industry',
    (row) =>
      row.companyRevenueLower !== null && row.companyRevenueLower !== undefined
        ? this.employeeRange.find((item) => item.value === row.employeeRangeLower)
          ? this.employeeRange.find((item) => item.value === row.employeeRangeLower).name
          : 'N/A'
        : 'N/A',

    (row) =>
      row.companyRevenueLower !== null && row.companyRevenueLower !== undefined
        ? this.companyRevenue.find((item) => item.value === row.companyRevenueLower)
          ? this.companyRevenue.find((item) => item.value === row.companyRevenueLower).name
          : 'N/A'
        : 'N/A',
  ];

  searchCandidates = async (page, count) => {
    let payload = {};
    payload.firstName = this.state.firstName ? this.state.firstName.toLowerCase() : null;
    payload.middleName = this.state.middleName ? this.state.middleName.toLowerCase() : null;
    payload.lastName = this.state.lastName ? this.state.lastName.toLowerCase() : null;
    payload.email = this.state.email ? this.state.email.toLowerCase() : null;
    payload.name = this.state.name ? this.state.name.toLowerCase() : null;
    payload.gender = this.state.gender ? this.state.gender : null;
    payload.city = this.state.city ? this.state.city.toLowerCase() : null;
    debugger;
    payload.dobFrom = this.state.dobFrom
      ? moment(`${this.state.dobFrom}-1-1 12:00:00`, 'YYYY-MM-DD hh:mm:ss').toDate()
      : null;
    payload.dobTo = this.state.dobTo
      ? moment(`${this.state.dobTo}-1-1 12:00:00`, 'YYYY-MM-DD hh:mm:ss').toDate()
      : null;
    payload.zip = this.state.zip ? this.state.zip : null;
    payload.employmentStatus =
      this.state.employmentStatus && this.state.employmentStatus.length > 0 ? this.state.employmentStatus : null;
    payload.industry = this.state.industry ? this.state.industry : null;
    payload.function = this.state.function ? this.state.function : null;
    payload.education = this.state.education ? this.state.education : null;
    payload.state = this.state.state ? this.createBooleanSearch(this.state.state) : null;
    payload.seniority = this.state.seniority ? this.state.seniority : null;
    payload.employeeRangeFrom = this.state.employeeRangeFrom ? this.state.employeeRangeFrom : null;
    payload.employeeRangeTo = this.state.employeeRangeTo ? this.state.employeeRangeTo : null;
    payload.revenueRangeFrom = this.state.revenueRangeFrom ? this.state.revenueRangeFrom : null;
    payload.revenueRangeTo = this.state.revenueRangeTo ? this.state.revenueRangeTo : null;
    payload.company = this.state.company ? this.state.company : null;
    // payload.jobTitle = this.state.jobTitle ? this.state.jobTitle : null;
    payload.country = this.state.country ? this.state.country : null;
    // payload.jobStartDate = this.state.jobStartDate ? this.state.jobStartDate : null;

    payload.companyName = this.state.companyName ? this.createBooleanSearch(this.state.companyName) : null;
    payload.jobTitle = this.state.jobTitle ? this.createBooleanSearch(this.state.jobTitle) : null;

    if (this.start_date.getData()) {
      payload.employmentStartDate = this.start_date.getData();
    }

    if (this.consent_date.getData()) {
      payload.consentDate = this.consent_date.getData();
    }

    let keyz = Object.keys(payload);
    let empty = true;
    for (let key of keyz) {
      if (payload[key] !== null) {
        empty = false;
      }
    }
    if (empty) {
      this.getCandidates(page, count);
    } else {
      payload.page = page;
      payload.count = count;
      let { data, error } = await Request.post({ url: Request.requestMapping.search, data: payload });
      if (data) {
        this.setState({ users: data, totalCount: data.length });
      }
    }
  };

  createBooleanSearch = (theString, isWildCard = true) => {
    if (Array.isArray(theString)) {
      theString = theString.join(' OR ');
    }
    if (isWildCard) {
      return `%(${theString.replace(/"/g, '').replace(/ OR /gi, '|').toLowerCase()})%`;
    }
    return `(${theString.replace(/"/g, '').replace(/ OR /gi, '|').toLowerCase()})`;
  };

  resetSearchQuery = () => {
    if (this.countryRef) {
      this.countryRef.clear();
    }
    this.setState({
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      name: '',
      gender: '',
      city: '',
      dobFrom: '',
      dobTo: '',
      zip: '',
      employmentStatus: null,
      industry: null,
      function: null,
      education: null,
      state: '',
      seniority: null,
      employeeRangeFrom: null,
      employeeRangeTo: null,
      revenueRangeFrom: null,
      revenueRangeTo: null,
      company: null,
      country: '',
      companyName: '',
      jobTitle: '',
      consentDate: '',
    });
    this.countryRef.clearData();
    this.genderRef.clearData();
    if (this.state.country && this.state.country.includes(194)) {
      this.stateRef.clearData();
    }

    this.educationRef.clearData();
    this.empStatusRef.clearData();
    this.industryRef.clearData();
    this.empRangeRef.clearData();
    this.revenueRef.clearData();
    this.functionRef.clearData();
    this.seniorityRef.clearData();

    this.dobFromRef.clearData();
    this.dobToRef.clearData();

    this.start_date.clearData();
    this.consent_date.clearData();
  };

  genders = ['Prefer Not To Disclose', 'Male', 'Female', 'Other'];
  employmentStatus = [
    {
      value: 1,
      name: 'Employed full time (more than 30 hours per week)',
    },
    {
      value: 2,
      name: 'Employed part time (less than 30 hours per week)',
    },
    {
      value: 3,
      name: 'Company Founder / Owner',
    },
    {
      value: 4,
      name: 'Consultant / Freelance',
    },
    {
      value: 5,
      name: 'Student',
    },
    {
      value: 6,
      name: 'Unemployed',
    },
    {
      value: 7,
      name: 'Retired',
    },
  ];

  seniority = [
    {
      value: 1,
      name: 'CEO, President, Chairman, Partner',
    },
    {
      value: 2,
      name: 'Other C-level Executive e.g. COO/CFO/CIO/CTO/CMO etc.',
    },
    {
      value: 3,
      name: 'Vice President e.g. VP/SVP/EVP',
    },
    {
      value: 4,
      name: 'Senior Director / Director',
    },
    {
      value: 5,
      name: 'Senior Manager / Manager',
    },
    {
      value: 6,
      name: 'Senior Professional / Expert',
    },
    {
      value: 7,
      name: 'Individual Contributor / Staff',
    },
    {
      value: 8,
      name: 'Intern / Entry Level',
    },
  ];

  education = [
    {
      value: 1,
      name: 'Some high school, no diploma',
    },
    {
      value: 2,
      name: 'High school graduate, diploma, or GED',
    },
    {
      value: 3,
      name: 'Some college coursework, no degree',
    },
    {
      value: 4,
      name: 'Bachelor’s degree',
    },
    {
      value: 5,
      name: 'Master’s degree',
    },
    {
      value: 6,
      name: 'Doctorate degree',
    },
  ];

  // companyRevenue = [
  //   { name: 'Less than $500,000', value: [0, 5] },
  //   { name: '$500,000 to less than $1 million', value: [5, 10] },
  //   { name: '$1 million to less than $2 million', value: [10, 20] },
  //   { name: '$2 million to less than $5 million', value: [20, 50] },
  //   { name: '$5 million to less than $10 million', value: [50, 100] },
  //   { name: '$10 million to less than $25 million', value: [100, 250] },
  //   { name: '$25 million to less than $50 million', value: [250, 500] },
  //   { name: '$50 million to less than $100 million', value: [500, 1000] },
  //   { name: '$100 million to less than $250 million', value: [1000, 2500] },
  //   { name: '$250 million to less than $500 million', value: [2500, 5000] },
  //   { name: '$500 million to less than $1 billion', value: [5000, 10000] },
  //   { name: '$1 billion to less than $2 billion', value: [10000, 20000] },
  //   { name: '$2 billion to less than $3 billion', value: [20000, 30000] },
  //   { name: '$3 billion to less than $5 billion', value: [30000, 50000] },
  //   { name: '$5 billion to less than $10 billion', value: [50000, 1000000] },
  //   { name: 'More than $10 billion', value: [100000, 500000] },
  // ];

  companyRevenue = [
    { name: 'Less than $500,000', value: 0 },
    { name: '$500,000 to less than $1 million', value: 5 },
    { name: '$1 million to less than $2 million', value: 10 },
    { name: '$2 million to less than $5 million', value: 20 },
    { name: '$5 million to less than $10 million', value: 50 },
    { name: '$10 million to less than $25 million', value: 100 },
    { name: '$25 million to less than $50 million', value: 250 },
    { name: '$50 million to less than $100 million', value: 500 },
    { name: '$100 million to less than $250 million', value: 1000 },
    { name: '$250 million to less than $500 million', value: 2500 },
    { name: '$500 million to less than $1 billion', value: 5000 },
    { name: '$1 billion to less than $2 billion', value: 10000 },
    { name: '$2 billion to less than $3 billion', value: 20000 },
    { name: '$3 billion to less than $5 billion', value: 30000 },
    { name: '$5 billion to less than $10 billion', value: 50000 },
    { name: 'More than $10 billion', value: 100000 },
  ];

  // employeeRange = [
  //   { name: 'I am the only employee', value: [0, 1] },
  //   { name: '2 to less than 5', value: [2, 5] },
  //   { name: '5 to less than 10', value: [5, 10] },
  //   { name: '10 to less than 25', value: [10, 25] },
  //   { name: '25 to less than 50', value: [25, 50] },
  //   { name: '50 to less than 100', value: [50, 100] },
  //   { name: '100 to less than 250', value: [100, 250] },
  //   { name: '250 to less than 500', value: [250, 500] },
  //   { name: '500 to less than 1,000', value: [500, 1000] },
  //   { name: '1,000 to less than 2,500', value: [1000, 2500] },
  //   { name: '2,500 to less than 5,000', value: [2500, 5000] },
  //   { name: '5,000 to less than 10,000', value: [5000, 10000] },
  //   { name: '10,000 to less than 20,000', value: [10000, 20000] },
  //   { name: '20,000 to less than 30,000', value: [20000, 30000] },
  //   { name: '30,000 to less than 40,000', value: [30000, 40000] },
  //   { name: '40,000 to less than 50,000', value: [40000, 50000] },
  //   { name: 'More than 50,000', value: [50000, 5000000] },
  // ];
  employeeRange = [
    { name: 'I am the only employee', value: 0 },
    { name: '2 to less than 5', value: 2 },
    { name: '5 to less than 10', value: 5 },
    { name: '10 to less than 25', value: 10 },
    { name: '25 to less than 50', value: 25 },
    { name: '50 to less than 100', value: 50 },
    { name: '100 to less than 250', value: 100 },
    { name: '250 to less than 500', value: 250 },
    { name: '500 to less than 1,000', value: 500 },
    { name: '1,000 to less than 2,500', value: 1000 },
    { name: '2,500 to less than 5,000', value: 2500 },
    { name: '5,000 to less than 10,000', value: 5000 },
    { name: '10,000 to less than 20,000', value: 10000 },
    { name: '20,000 to less than 30,000', value: 20000 },
    { name: '30,000 to less than 40,000', value: 30000 },
    { name: '40,000 to less than 50,000', value: 40000 },
    { name: 'More than 50,000', value: 50000 },
  ];

  getCountries = async () => {
    let countryArray = await Request.get({ url: Request.requestMapping.util.countries });
    if (countryArray.data) {
      this.setState({ countries: countryArray.data });
    }
  };

  getStates = async () => {
    let stateArray = await Request.get({ url: Request.requestMapping.util.states });
    this.setState({ americanStates: stateArray.data });
    return stateArray;
  };

  getFunctions = async () => {
    let functionsArray = await Request.get({ url: Request.requestMapping.util.functions });
    if (functionsArray.data) {
      this.setState({ functions: functionsArray.data });
    }
  };

  getIndustries = async () => {
    let industries = await Request.get({ url: Request.requestMapping.util.industries });
    if (industries.data) {
      this.setState({ industries: industries.data });
    }
  };

  getJobTitles = async (searchVal) => {
    let { data, error } = await Request.get({
      params: { name: searchVal },
      url: Request.requestMapping.util.jobTitles,
    });
    if (data) {
      return data.map((item) => {
        return { id: item[0], name: item[1] };
      });
    }
    throw error;
  };

  openClientPage = (client) => {
    window.open(`/clients/${client.id}`, '_blank');
  };

  parseValue = (key, user) => {
    switch (key) {
      case 'employmentStatus':
        return user.employmentStatus !== null &&
          user.employmentStatus !== undefined &&
          this.employmentStatus.findIndex((item) => item.value === user.employmentStatus) !== -1
          ? this.employmentStatus.find((item) => item.value === user.employmentStatus).name
          : 'N/A';
      case 'seniority':
        return user.seniority !== null &&
          user.seniority !== undefined &&
          this.seniority.findIndex((item) => item.value === user.seniority)
          ? this.seniority.find((item) => item.value === user.seniority).name
          : 'N/A';
      case 'function':
        return user.function !== null &&
          user.function !== undefined &&
          this.state.functions.findIndex((item) => item.id === user.function)
          ? this.state.functions.find((item) => item.id === user.function).name
          : 'N/A';
      case 'education':
        return user.education != null &&
          user.education !== undefined &&
          this.education.findIndex((item) => item.value === user.education)
          ? this.education.find((item) => item.value === user.education).name
          : 'N/A';

      case 'companyRevenueLower':
        if (user.companyRevenueLower !== null && user.companyRevenueLower !== undefined) {
          let compRevIndex = this.companyRevenue.findIndex((item) => item.value === user.companyRevenueLower);
          return compRevIndex !== -1 ? this.companyRevenue[compRevIndex].name : 'N/A';
        }
        return 'N/A';

      case 'employeeRangeLower':
        if (user.employeeRangeLower !== null && user.employeeRangeLower !== undefined) {
          let empIndex = this.employeeRange.findIndex((item) => item.value === user.employeeRangeLower);
          return empIndex !== -1 ? this.employeeRange[empIndex].name : 'N/A';
        }
        return 'N/A';

      case 'gender':
        return user.gender !== null && user.gender !== undefined && this.genders[user.gender]
          ? this.genders[user.gender]
          : 'N/A';

      case 'country_code':
        if (user.country_code !== null && user.country_code !== undefined) {
          let countryIndex = this.state.countries.find((item) => item.id === user.country_code);
          return countryIndex !== -1 ? this.state.countries[countryIndex].name : 'N/A';
        }
        return 'N/A';
      case 'signOnPaid':
        return user.signOnPaid ? 'YES' : 'NO';
      default:
        return user[key];
    }
  };

  generateCSV = async (done) => {
    let csv = [
      [
        'ID',
        'Consent Date (mm/dd/yyyy)',
        'Bonus paid',
        'Username',
        'First Name',
        'Middle Name',
        'Last Name',
        'Date Of Birth (mm/dd/yyyy)',
        'Gender',
        'Country',
        'State',
        'City',
        'Zip',
        'Mobile',
        'Phone',
        'Email',
        'Employment Status',
        'Employment Start Date (mm/dd/yyyy)',
        'Seniority',
        'Function',
        'Job Title',
        'Education',
        'Company',
        'Industry',
        'Company Revenue',
        'Employee Range',
      ],
    ];

    for (const user of this.state.users) {
      let empStat = 'N/A';
      if (user.employmentStatus !== null && user.employmentStatus !== undefined) {
        let empStatIndex = this.employmentStatus.findIndex((item) => item.value === user.employmentStatus);
        empStat = empStatIndex !== -1 ? this.employmentStatus[empStatIndex].name : 'N/A';
      }

      let sen = 'N/A';
      if (user.seniority !== null && user.seniority !== undefined) {
        let senIndex = this.seniority.findIndex((item) => item.value === user.seniority);
        sen = senIndex !== -1 ? this.seniority[senIndex].name : 'N/A';
      }

      let func = 'N/A';
      if (user.function !== null && user.function !== undefined) {
        let funIndex = this.state.functions.findIndex((item) => item.id === user.function);
        func = funIndex !== -1 ? this.state.functions[funIndex].name : 'N/A';
      }

      let education = 'N/A';
      if (user.education !== null && user.education !== undefined) {
        let eduIndex = this.education.findIndex((item) => item.value === user.education);
        education = eduIndex !== -1 ? this.education[eduIndex].name : 'N/A';
      }
      let revenue = 'N/A';
      if (user.companyRevenueLower !== undefined && user.companyRevenueLower !== null) {
        let compRevIndex = this.companyRevenue.findIndex((item) => item.value === user.companyRevenueLower);
        revenue = compRevIndex !== -1 ? this.companyRevenue[compRevIndex].name : 'N/A';
      }
      let empRange = 'N/A';
      if (user.employeeRangeLower !== undefined && user.employeeRangeLower !== null) {
        let empRangeIndex = this.employeeRange.findIndex((item) => item.value === user.employeeRangeLower);
        empRange = empRangeIndex !== -1 ? this.employeeRange[empRangeIndex].name : 'N/A';
      }
      let empGender = 'N/A';
      if (user.gender !== null && user.gender !== undefined) {
        let genderToSet = this.genders[user.gender];
        empGender = genderToSet ? genderToSet : 'N/A';
      }

      let userCountry = 'N/A';
      if (user.country_code !== null && user.country_code !== undefined) {
        let countryIndex = this.state.countries.findIndex((item) => item.id === user.country_code);
        userCountry = countryIndex !== -1 ? this.state.countries[countryIndex].name : 'N/A';
      }

      csv.push([
        user.id,
        user.consentDate ? moment(user.consentDate).format('MM/DD/YYYY') : 'N/A',
        user.signOnPaid ? 'Yes' : 'No',
        user.username ? user.username : 'N/A',
        user.firstName ? user.firstName : 'N/A',
        user.middleName ? user.middleName : 'N/A',
        user.lastName ? user.lastName : 'N/A',
        user.dob ? user.dob : 'N/A',
        empGender,
        userCountry,
        user.state ? user.state : 'N/A',
        user.city ? user.city : 'N/A',
        user.zip ? user.zip : 'N/A',
        user.phone ? user.phone : 'N/A',
        user.mobile ? user.mobile : 'N/A',
        user.email ? user.email : 'N/A',
        empStat ? empStat : 'N/A',
        user.employmentStartDate ? user.employmentStartDate : 'N/A',
        sen ? sen : 'N/A',
        func ? func : 'N/A',
        user.jobTitle ? user.jobTitle : 'N/A',
        education ? education : 'N/A',
        user.company ? user.company : 'N/A',
        user.industry ? user.industry : 'N/A',
        revenue ? revenue : 'N/A',
        empRange ? empRange : 'N/A',
      ]);
    }

    this.setState(
      {
        csvData: csv,
      },
      () => done()
    );
  };

  getCompanies = async (searchVal) => {
    let { data, error } = await Request.get({
      params: { name: searchVal },
      url: Request.requestMapping.util.companies,
    });
    if (data) {
      this.setState({ companies: data });
      return data;
    }
    throw error;
  };

  componentDidMount() {
    this.getCandidates();
    this.getCountries();
    this.getStates();
    this.getFunctions();
    this.getIndustries();

    // this.setState({ labelWidth: this.inputLabel.current.offsetWidth });
  }

  setBonusPaid = async (item, id) => {
    let { data, error } = await Request.get({ url: Request.requestMapping.setBonusPaid + '/' + item.id });
    if (data) {
      let users = this.state.users;
      item.signOnPaid = !item.signOnPaid;
      users[id] = item;
      this.setState({ users: users });
    }
  };

  getCandidates = async (params) => {
    if (params && _.isObject(params.role)) {
      params.role = params.role.name;
    }
    let { data, error } = await CandidatesHandler.getCandidates(params);
    if (data) {
      this.setState({ users: data, totalCount: data.length });
    }
  };

  handleChange = (prop) => (event) => {
    this.setState(
      {
        [prop]: event.target
          ? event.target.value
            ? event.target.value
            : event.target.checked
            ? event.target.checked
            : ''
          : event,
      },
      () => console.log(this.state)
    );
  };

  openProfile = (event) => {
    event.preventDefault();
    debugger;
    this.openClientPage({ id: event.target.profileId.value });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className="clients" style={{ minWidth: 'fit-content' }}>
        <div style={{ padding: '40px 40px 60px 40px' }}>
          <h3 style={{ color: '#5ab2a5', fontSize: '26px', marginBottom: '16px' }}>MEMBERS</h3>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ fontSize: '26px', marginRight: '24px' }}>TH ID</span>
            <form onSubmit={this.openProfile} style={{ display: 'flex', width: '300px' }}>
              <THInput
                style={{ marginTop: '0px' }}
                noPadding={true}
                ref={(ref) => (this.email = ref)}
                type="text"
                className="second-column-input"
                name={'profileId'}
                initialLoad={this.state.loading}
                defaultValue={''}
              />
              <button
                type="submit"
                style={{ width: '200px', marginLeft: '15px', padding: '0 10px' }}
                className="search-button"
              >
                OPEN PROFILE
              </button>
            </form>
          </div>
          {/* <Paper className={classes.root} elevation={1}> */}
          <div
            style={{
              width: '100%',
              display: 'grid',
              gridColumnGap: '10px',
              gridTemplateColumns: '1fr 1fr 1fr',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <h3 style={{ marginTop: '48px', color: '#5ab2a5', fontSize: '26px', gridColumn: '1/4' }}>
              Personal Information
            </h3>
            <div>
              <THInput
                ref={(ref) => (this.email = ref)}
                type="text"
                className="second-column-input"
                name={'email'}
                initialLoad={this.state.loading}
                label={() => <span style={{ fontSize: '16px' }}>Email Address</span>}
                onChange={this.handleChange('email')}
                value={this.state.email}
              />
            </div>
            <div>
              <THMultiSelect
                ref={(ref) => (this.countryRef = ref)}
                setSelected={(selected) => this.setState({ country: selected.map((item) => item.id) })}
                displayName={'name'}
                data={this.state.countries}
                label={() => <span style={{ fontSize: '16px' }}>Country</span>}
              ></THMultiSelect>
            </div>

            {/* <div style={{ display: 'flex', margin: '4px', width: '100%', marginTop: '12px' }}>
              <FormControl variant="outlined" style={{ width: '80%', margin: 'auto', minWidth: '120px' }}>
                <InputLabel ref={this.inputLabel} id="demo-simple-select-outlined-label">
                  Gender
                </InputLabel>
                <Select
                  input={<OutlinedInput name="age" id="outlined-age-simple" />}
                  style={{ width: '100%', margin: 'auto' }}
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={this.state.gender}
                  onChange={this.handleChange('gender')}
                  labelWidth={50}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={1}>Male</MenuItem>
                  <MenuItem value={2}>Female</MenuItem>
                  <MenuItem value={3}>Other</MenuItem>
                  <MenuItem value={4}>Prefer Not To Disclose</MenuItem>
                </Select>
              </FormControl>
            </div> */}
            <div>
              <THMultiSelect
                ref={(ref) => (this.genderRef = ref)}
                setSelected={(selected) => this.setState({ gender: selected.map((item) => item.value) })}
                displayName={'name'}
                data={[
                  {
                    value: 1,
                    name: 'Male',
                  },
                  {
                    value: 2,
                    name: 'Female',
                  },
                  {
                    value: 3,
                    name: 'Other',
                  },
                  {
                    value: 0,
                    name: 'Prefer not to disclose',
                  },
                ]}
                label={() => <span style={{ fontSize: '16px' }}>Gender</span>}
              ></THMultiSelect>
            </div>
            {/* <div className="second-column-input">
              <THSelect
                ref={(ref) => (this.gender = ref)}
                className="second-column-input"
                name={'gender'}
                label={() => <span style={{ fontSize: '16px' }}>Gender</span>}
                note={''}
                dataSource={() => ({
                  data: [
                    {
                      value: 1,
                      name: 'Male',
                    },
                    {
                      value: 2,
                      name: 'Female',
                    },
                    {
                      value: 3,
                      name: 'Other',
                    },
                    {
                      value: 0,
                      name: 'Prefer not to disclose',
                    },
                  ],
                })}
                initialLoad={this.state.loading}
                displayName={'name'}
                comparisonValue="value"
                handleChange={(val) => this.setState({ gender: val.value })}
                defaultValue={this.state.gender}
              />
            </div> */}
            <div>
              <THInput
                ref={(ref) => (this.firstName = ref)}
                type="text"
                className="second-column-input"
                name={'first_name'}
                initialLoad={this.state.loading}
                label={() => <span style={{ fontSize: '16px' }}>First Name</span>}
                onChange={this.handleChange('firstName')}
                value={this.state.firstName}
              />
            </div>
            <div className="first-column-input">
              {this.state.country && this.state.country.includes(194) && (
                <React.Fragment>
                  <THMultiSelect
                    ref={(ref) => (this.stateRef = ref)}
                    setSelected={(selected) => this.setState({ state: selected.map((item) => item.abbreviation) })}
                    displayName={'abbreviation'}
                    name={'state'}
                    data={this.state.americanStates}
                    label={() => <span style={{ fontSize: '16px' }}>State/Province</span>}
                  ></THMultiSelect>
                </React.Fragment>
              )}

              {(this.state.country === '' || (this.state.country && !this.state.country.includes(194))) && (
                <THInput
                  ref={(ref) => (this.state_input = ref)}
                  name={'state_input'}
                  className="first-column-input"
                  label={() => <span style={{ fontSize: '16px' }}>State/Province</span>}
                  initialLoad={this.state.loading}
                  onChange={this.handleChange('state')}
                  value={this.state.state}
                />
              )}
            </div>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '24px' }}>
              <THSelect
                ref={(ref) => (this.dobFromRef = ref)}
                name={'year'}
                placeholderOption="Year"
                note={''}
                label={() => <span style={{ fontSize: '16px' }}>Date of Birth - From</span>}
                displayName={'name'}
                comparisonValue="value"
                value={this.state.dobFrom}
                handleChange={(val) => this.setState({ dobFrom: val.value })}
                dataSource={years}
              />
              <THSelect
                ref={(ref) => (this.dobToRef = ref)}
                name={'year'}
                placeholderOption="Year"
                note={''}
                label={() => <span style={{ fontSize: '16px' }}>Date of Birth - To</span>}
                displayName={'name'}
                comparisonValue="value"
                value={this.state.dobTo}
                handleChange={(val) => this.setState({ dobTo: val.value })}
                dataSource={years}
              />
            </div>
            <div>
              <THInput
                ref={(ref) => (this.lastName = ref)}
                type="text"
                className="second-column-input"
                name={'last_name'}
                initialLoad={this.state.loading}
                label={() => <span style={{ fontSize: '16px' }}>Middle Name</span>}
                onChange={this.handleChange('middleName')}
                value={this.state.middleName}
              />
            </div>
            <div>
              <THInput
                ref={(ref) => (this.lastName = ref)}
                type="text"
                className="second-column-input"
                name={'last_name'}
                initialLoad={this.state.loading}
                label={() => <span style={{ fontSize: '16px' }}>City/Town</span>}
                onChange={this.handleChange('city')}
                value={this.state.city}
              />
            </div>
            <div>
              <THMultiSelect
                ref={(ref) => (this.educationRef = ref)}
                setSelected={(selected) => this.setState({ education: selected.map((item) => item.value) })}
                displayName={'name'}
                data={this.education}
                label={() => <span style={{ fontSize: '16px' }}>Highest level of Education</span>}
              ></THMultiSelect>
            </div>
            <div>
              <THInput
                ref={(ref) => (this.lastName = ref)}
                type="text"
                className="second-column-input"
                name={'last_name'}
                initialLoad={this.state.loading}
                label={() => <span style={{ fontSize: '16px' }}>Last Name</span>}
                onChange={this.handleChange('lastName')}
                value={this.state.lastName}
              />
            </div>
            <div>
              <THInput
                ref={(ref) => (this.lastName = ref)}
                type="text"
                className="second-column-input"
                name={'last_name'}
                initialLoad={this.state.loading}
                label={() => <span style={{ fontSize: '16px' }}>Zip/Postal Code</span>}
                onChange={this.handleChange('zip')}
                value={this.state.zip}
              />
            </div>

            <div>
              <THMultiSelect
                ref={(ref) => (this.empStatusRef = ref)}
                setSelected={(selected) => this.setState({ employmentStatus: selected.map((item) => item.value) })}
                displayName={'name'}
                data={this.employmentStatus}
                label={() => <span style={{ fontSize: '16px' }}>Current Employment Status</span>}
              ></THMultiSelect>
            </div>
            <div>
              <h3 style={{ color: '#5ab2a5', fontSize: '26px', gridColumn: '1/5' }}>Company Information</h3>
              <div>
                <THInput
                  ref={(ref) => (this.lastName = ref)}
                  type="text"
                  className="second-column-input"
                  name={'last_name'}
                  initialLoad={this.state.loading}
                  label={() => <span style={{ fontSize: '16px' }}>Company Name</span>}
                  onChange={this.handleChange('companyName')}
                  value={this.state.companyName}
                />
              </div>
              <div>
                <THMultiSelect
                  ref={(ref) => (this.industryRef = ref)}
                  setSelected={(selected) => this.setState({ industry: selected.map((item) => item.id) })}
                  displayName={'name'}
                  data={this.state.industries}
                  label={() => <span style={{ fontSize: '16px' }}>Industry</span>}
                ></THMultiSelect>
              </div>
              <div>
                <THMultiSelect
                  ref={(ref) => (this.empRangeRef = ref)}
                  setSelected={(selected) => this.setState({ employeeRangeFrom: selected.map((item) => item.value) })}
                  displayName={'name'}
                  data={this.employeeRange}
                  label={() => <span style={{ fontSize: '16px' }}>Employee Range</span>}
                ></THMultiSelect>
              </div>
              <div>
                <THMultiSelect
                  ref={(ref) => (this.revenueRef = ref)}
                  setSelected={(selected) => this.setState({ revenueRangeFrom: selected.map((item) => item.value) })}
                  displayName={'name'}
                  data={this.companyRevenue}
                  label={() => <span style={{ fontSize: '16px' }}>Company Revenue</span>}
                ></THMultiSelect>
              </div>
            </div>

            <div>
              <h3 style={{ color: '#5ab2a5', fontSize: '26px', gridColumn: '1/5' }}>Professional Information</h3>
              <div>
                <THMultiSelect
                  ref={(ref) => (this.functionRef = ref)}
                  setSelected={(selected) => this.setState({ function: selected.map((item) => item.id) })}
                  displayName={'name'}
                  data={this.state.functions}
                  label={() => <span style={{ fontSize: '16px' }}>Function</span>}
                ></THMultiSelect>
              </div>
              <div>
                <THMultiSelect
                  ref={(ref) => (this.seniorityRef = ref)}
                  setSelected={(selected) => this.setState({ seniority: selected.map((item) => item.value) })}
                  displayName={'name'}
                  data={this.seniority}
                  label={() => <span style={{ fontSize: '16px' }}>Seniority</span>}
                ></THMultiSelect>
              </div>

              <div>
                <THSimpleDatePicker
                  noBottomMargin={true}
                  ref={(ref) => (this.start_date = ref)}
                  name={'start_date'}
                  label={() => <span style={{ fontSize: '16px' }}>Employment start date</span>}
                  initialLoad={this.state.loading}
                  defaultValue={this.state.jobStartDate}
                ></THSimpleDatePicker>
              </div>

              <div>
                <THInput
                  ref={(ref) => (this.lastName = ref)}
                  type="text"
                  className="second-column-input"
                  name={'last_name'}
                  initialLoad={this.state.loading}
                  label={() => <span style={{ fontSize: '16px' }}>Job Title</span>}
                  onChange={this.handleChange('jobTitle')}
                  value={this.state.jobTitle}
                />
              </div>
            </div>
            <div style={{ height: '100%' }}>
              <h3 style={{ color: '#5ab2a5', fontSize: '26px', gridColumn: '1/5' }}>Admin Information</h3>
              <div>
                <THSimpleDatePicker
                  noBottomMargin={true}
                  ref={(ref) => (this.consent_date = ref)}
                  name={'consent_date'}
                  label={() => <span style={{ fontSize: '16px' }}>Consent date</span>}
                  initialLoad={this.state.loading}
                  defaultValue={this.state.consentDate}
                ></THSimpleDatePicker>
              </div>
            </div>
          </div>
          <div style={{ margin: '6px auto', minHeight: '50px' }}>
            <div
              style={{
                padding: '16px',
                width: '100%',
                display: 'grid',
                gridColumnGap: '20px',
                gridTemplateColumns: '1fr 1fr',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <CSVLink
                  style={{ textDecoration: 'none', color: 'inherit' }}
                  data={this.state.csvData}
                  asyncOnClick={true}
                  onClick={(event, done) => {
                    this.generateCSV(() => {
                      done();
                    });
                  }}
                >
                  <button style={{ width: '150px', padding: '15px 10px' }} className="search-button">
                    EXPORT CSV
                  </button>
                </CSVLink>
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <span style={{ padding: '0 48px' }}>
                  <button className="search-button" onClick={() => this.resetSearchQuery()}>
                    CLEAR SEARCH
                  </button>
                </span>
                <span>
                  <button
                    className="search-button-invert"
                    style={{ padding: '15px 60px' }}
                    onClick={() => this.searchCandidates()}
                  >
                    SEARCH {'    '}
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>

        <THTable
          count={this.state.count}
          totalCount={this.state.totalCount}
          setPage={(val) => this.setState({ page: val })}
          setCount={(val) => this.setState({ count: val.val, page: 0 })}
          page={this.state.page}
          onRowClick={(row) => this.openClientPage(row)}
          headers={[
            'TH ID',
            'Consent Date',
            'First Name',
            'Last Name',
            'Country',
            'Function',
            'Seniority',
            'Company name',
            'Industry',
            'Employee Range',
            'Company revenue',
          ]}
          dataSource={this.state.users}
          dataDefs={this.dataDefs}
        ></THTable>
      </div>
    );
  }
}
// export default withStyles(styles)(withAlerts(withRole(ClientsPage)));
export default withStyles(styles)(withRole(ClientsPage));
