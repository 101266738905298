import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Redirect } from 'react-router';
import Cookies from 'js-cookie';
import './styles.scss';
import Request from '../../shared/logic/http/request';
import moment from 'moment';
import { withRole } from '../../shared/logic/context-providers/role-provider';
import { TextField, Button } from '@material-ui/core';
import jwt_decode from 'jwt-decode';
import THMultiSelect from '../../shared/components/select/multiselect';
import THSelect from '../../shared/components/select/select';

class LoginPage extends Component {
  state = {
    error: '',
    loggedIn: false,
    forgotUsernameDialog: false,
    forgotPasswordDialog: false,
    passwordResetEmail: '',
  };
  login = async (event) => {
    event.preventDefault();
    let username = event.target.username.value;
    let password = '' + event.target.password.value;
    let foreverLogin = event.target.forever_login.checked;
    let payload = {
      username: username.toLowerCase(),
      password: password,
    };

    let { data, error, headers } = await Request.post({
      url: Request.requestMapping.login,
      data: payload,
    });

    if (data) {
      var decoded = jwt_decode(data);

      let userObject;

      if (decoded.sub) {
        userObject = JSON.parse(decoded.sub);
      } else {
        return;
      }

      if (userObject.user && userObject.user.role) {
        let role = userObject.user.role;
        if (role !== 'ROLE_ADMIN') {
          return;
        }
      } else {
        return;
      }

      Request.setAuth(data);

      this.props.setToken(data);
      Cookies.remove('th-userinfo');

      if (foreverLogin) {
        Cookies.set('th-userinfo', data);
      }
      this.setState({ loggedIn: true });
      if (this.props.history) {
        this.props.history.push('/clients');
      }
      return;
    }
    if (error) {
      if (error.message.error === 'Unauthorized') {
        let errorMessage = typeof error === 'object' ? error.message.message : JSON.parse(error.message.message);
        if (errorMessage.locked) {
        } else {
        }

        return;
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.state.loggedIn && <Redirect to="/clients"></Redirect>}
        {!this.state.loggedIn && (
          <div>
            <div className="login-wrapper">
              <div className="login-container">
                <section>
                  <div className="hero">
                    <div className="hero-elements">
                      <form style={{ marginTop: '24px' }} onSubmit={this.login}>
                        <div style={{ display: 'flex' }}>
                          <TextField
                            style={{ margin: 'auto' }}
                            ref={(ref) => (this.username = ref)}
                            name={'username'}
                            errorMessage=" "
                            label="Username"
                          />
                        </div>
                        <div style={{ display: 'flex' }}>
                          <TextField
                            style={{ margin: 'auto' }}
                            ref={(ref) => (this.password = ref)}
                            name={'password'}
                            type="password"
                            label="Password"
                          />
                        </div>
                        <div style={{ paddingTop: '24px', width: '100%', display: 'flex', justifyContent: 'center' }}>
                          <Button variant="contained" type="submit">
                            LOG IN
                          </Button>
                        </div>
                        <div
                          style={{
                            paddingTop: '24px',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                          }}
                        >
                        </div>
                        <div
                          style={{
                            width: '%',
                            display: 'flex',
                            justifyContent: 'center',
                            padding: '16px',
                            color: '#77787b',
                          }}
                        >
                          <label style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <input
                              style={{ width: '20px', height: '20px', margin: '16px' }}
                              type="checkbox"
                              name={'forever_login'}
                            />
                            <span>Keep me logged in</span>
                          </label>
                        </div>
                      </form>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default withRole(LoginPage);
