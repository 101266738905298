import React, { Component } from 'react';
import _ from 'lodash';
import './table.scss';
import Request from '../../logic/http/request';
import THSelect from '../select/select';
const THTableheader = (props) => {
  return (
    <tr className="unselectable">
      {props.headerNames.map((item, index) => (
        <th style={{ paddingBottom: '16px' }} key={`header-row${index}`}>
          {item}
        </th>
      ))}
    </tr>
  );
};

class THTable extends Component {
  state = {
    page: 0,
    perPage: 20,
    tableData: [],
    headers: [],
  };

  getCountries = async () => {
    let countryArray = await Request.get({ url: Request.requestMapping.util.countries });
    if (countryArray.data) {
      this.setState({ countries: countryArray.data });
    }
  };
  componentDidMount() {
    this.getCountries();
    if (_.isFunction(this.props.dataSource)) {
      this.getDataFromServer();
    }
  }

  getDataFromServer = async () => {
    let { data, error } = await this.props.dataSource();
    if (data) {
      this.setState({ tableData: data });
    }
  };

  render() {
    return (
      <div className="th-table-wrapper">
        <div className="result-counter">Results: {this.props.totalCount}</div>
        <table className="th-table-root container">
          <tbody>
            <THTableheader headerNames={this.props.headers}></THTableheader>
            {this.props.dataSource &&
              !_.isFunction(this.props.dataSource) &&
              this.props.dataSource
                .slice(this.props.page * this.props.count, (this.props.page + 1) * this.props.count)
                .map((row, rowIndex) => {
                  return (
                    <tr key={`row${rowIndex}`} className="hover" onClick={() => this.props.onRowClick(row)}>
                      {this.props.dataDefs.map((dataDef, index) => {
                        let val = _.isFunction(dataDef) ? dataDef(row) : _.get(row, dataDef);
                        return (
                          <td style={{ paddingLeft: index === 0 ? '30px' : '' }} key={`row${rowIndex}cell${index}`}>
                            {val}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
            {this.state.tableData &&
              this.state.tableData.map((row, rowIndex) => {
                return (
                  <tr key={`row${rowIndex}`} className="hover" onClick={() => this.props.onRowClick(row)}>
                    {this.props.dataDefs.map((dataDef, index) => {
                      let val = _.isFunction(dataDef) ? dataDef(row) : _.get(row, dataDef);
                      return <td key={`row${rowIndex}cell${index}`}>{val}</td>;
                    })}
                  </tr>
                );
              })}
          </tbody>
          <tfoot>
            <tr className="unselectable">
              <td colSpan="30">
                <div className="table-footer">
                  <div className="footer-controls">
                    <span
                      onClick={() => {
                        if (this.props.page > 0) {
                          this.props.setPage(this.props.page - 1);
                        }
                      }}
                      className="footer-control-arrow"
                    >
                      {'<'}
                    </span>
                    <span>
                      Page {this.props.page + 1} out of{' '}
                      {this.props.totalCount ? Math.ceil(this.props.totalCount / this.props.count) : '...'}
                    </span>
                    <span
                      onClick={() => {
                        if (Math.ceil(this.props.totalCount / this.props.count) > this.props.page + 1) {
                          this.props.setPage(this.props.page + 1);
                        }
                      }}
                      className="footer-control-arrow"
                    >
                      {' '}
                      {'>'}{' '}
                    </span>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <span style={{ marginRight: '8px', fontSize: '15px', paddingBottom: '8px' }}>Rows per page</span>
                    <div style={{ width: '65px' }}>
                      <THSelect
                        name={'count'}
                        // className="first-column-input"
                        dataSource={[{ val: 5 }, { val: 10 }, { val: 20 }, { val: 50 }, { val: 100 }]}
                        handleChange={(value) =>
                          this.props.setCount ? this.props.setCount(value) : console.log(value)
                        }
                        initialLoad={this.state.loading}
                        displayName={'val'}
                        comparisonValue="val"
                        defaultValue={this.props.count}
                      />
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    );
  }
}

export default THTable;
