import React, { Component } from 'react';
import './multiselect.scss';
import _ from 'lodash';

let multiselects = [];
class THMultiSelect extends Component {
  constructor(props) {
    super(props);
    multiselects.push(this);
    this.state = {
      dropdown: false,
      selecteditemsText: '',
      filter: '',
      selected: [],
    };
  }
  clear = () => {
    this.setState({
      selected: [],
      filter: '',
      selecteditemsText: '',
    });
  };

  clearData = () => {
    this.setState({
      dropdown: false,
      selecteditemsText: '',
      filter: '',
      selected: [],
    });
  };

  toggleSelection = (event, elem) => {
    event.stopPropagation();
    let newSelectedArray = [];
    if (
      this.state.selected.findIndex(
        (item) =>
          elem === item ||
          (this.props.displayName && _.get(item, this.props.displayName) === _.get(elem, this.props.displayName))
      ) !== -1
    ) {
      newSelectedArray = this.state.selected.filter(
        (item) =>
          item !== elem &&
          (!this.props.displayName || _.get(item, this.props.displayName) !== _.get(elem, this.props.displayName))
      );
    } else {
      newSelectedArray = [...this.state.selected, elem];
    }

    let newSelectedString = this.props.displayName
      ? newSelectedArray.reduce(
          (accumulator, currentValue) => accumulator + ', ' + _.get(currentValue, this.props.displayName),
          ''
        )
      : newSelectedArray.join(', ');

    if (newSelectedString.indexOf(',') === 0) {
      newSelectedString = newSelectedString.substring(1, newSelectedString.length);
    }
    let entireString = newSelectedString;
    newSelectedString = newSelectedString.length > 40 ? newSelectedString.substr(0, 39) + '...' : newSelectedString;
    this.setState({ tooltipText: entireString, selected: newSelectedArray, selecteditemsText: newSelectedString });
    if (this.props.setSelected) {
      this.props.setSelected(newSelectedArray);
    }
  };
  toggleDropdown = (e) => {
    if (e) {
      e.stopPropagation();
      multiselects.forEach((item) => {
        if (item !== this) {
          item.toggleDropdown();
        }
      });
      this.setState({ dropdown: !this.state.dropdown });
    } else {
      this.setState({ dropdown: false, filter: '' });
    }
  };

  closeDropdown = () => {
    this.setState({ dropdown: false });
  };

  componentDidMount() {
    window.addEventListener('click', this.closeDropdown);
  }

  componentWillUnmount() {
    multiselects = multiselects.filter((item) => item !== this);
    window.removeEventListener('click', this.closeDropdown);
  }

  isSelected(item) {
    return this.state.selected.includes(item);
  }

  renderOption = (item, index) => {
    const displayVal = this.props.displayName ? _.get(item, this.props.displayName) : item;

    return (
      <div
        className={this.isSelected(item) ? 'th-multiselect-list-option selected' : 'th-multiselect-list-option'}
        onClick={(event) => this.toggleSelection(event, item)}
        key={'multiselect-' + (this.props.name ? this.props.name + '-' : '-') + index}
      >
        {displayVal}
      </div>
    );
  };

  openTooltip = (event) => {
    this.setState({ tooltipOpen: true });
  };

  closeTooltip = (event) => {
    this.setState({ tooltipOpen: false });
  };

  render() {
    return (
      <div className="th-multiselect" onMouseEnter={this.openTooltip} onMouseLeave={this.closeTooltip}>
        {this.state.tooltipText &&
          this.state.tooltipOpen &&
          this.state.tooltipText.length !== this.state.selecteditemsText.length && (
            <div className="th-multiselect-tooltip">{this.state.tooltipText}</div>
          )}
        <div className="th-multi-container">
          <label className="label" htmlFor={this.props.name}>
            {this.props.required
              ? '* ' + (this.props.label && this.props.label())
              : this.props.label && this.props.label()}
          </label>
          <div value="text" className="th-multi-input" disabled onClick={this.toggleDropdown}>
            <div className="th-multiselect-selection">{this.state.selecteditemsText}</div>
          </div>
          {this.state.dropdown && (
            <div className="th-multiselect-list">
              <div>
                <span>
                  <input
                    value={this.state.filter}
                    onChange={(e) => this.setState({ filter: e.target.value })}
                    style={{ width: '100%', fontSize: '14px' }}
                    onClick={(e) => e.stopPropagation()}
                    onFocus={(e) => e.stopPropagation()}
                  ></input>
                </span>
              </div>
              {this.props.data &&
                this.props.data
                  .filter((item) =>
                    (this.props.displayName ? _.get(item, this.props.displayName) : item)
                      .toLowerCase()
                      .includes(this.state.filter.toLowerCase())
                  )
                  .map((item, index) => this.renderOption(item, index))}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default THMultiSelect;
