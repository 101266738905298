import React from 'react';
import _ from 'lodash';
import './input.scss';
export default class THInput extends React.Component {
  static inputId = 0;
  myRef = React.createRef();
  inputRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: this.props.errorMessage ? this.props.errorMessage : '',
      showCapsPopup: false,
    };
  }

  componentDidMount() {
    if (this.props.type === 'password') {
      document.addEventListener('keyup', this.wasCapsLockActivated);
      document.addEventListener('keydown', this.wasCapsLockDeactivated);
    }
  }

  componentWillUnmount() {
    if (this.props.type === 'password') {
      document.removeEventListener('keyup', this.wasCapsLockActivated);
      document.removeEventListener('keydown', this.wasCapsLockDeactivated);
    }
  }

  wasCapsLockActivated = (event) => {
    if (
      event.target === this.inputRef.current &&
      event.getModifierState &&
      event.getModifierState('CapsLock') &&
      this.state.showCapsPopup === false
    ) {
      this.setState({ showCapsPopup: true });
    }
  };

  wasCapsLockDeactivated = (event) => {
    if (
      event.target === this.inputRef.current &&
      event.getModifierState &&
      !event.getModifierState('CapsLock') &&
      this.state.showCapsPopup === true
    ) {
      this.setState({ showCapsPopup: false });
    }
  };

  setError = (errorMessage, dontScrollIntoView) => {
    this.setState({ errorMessage: errorMessage });
    if (!dontScrollIntoView) {
      this.scrollIntoView();
    }
  };

  setInfo = (infoMessage) => {
    this.setState({ infoMessage: infoMessage, errorMessage: '' });
  };

  scrollIntoView = () => {
    this.myRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
  };

  renderPlaceholder() {
    return <div className="placeholder"></div>;
  }

  handleOnChange = (event) => {
    if (this.props.onChange) {
      this.props.onChange(event);
    }
  };

  renderInput() {
    let { errorMessage, label, initialLoad, onChange, ...relevant } = { ...this.props };
    const style = this.props.lowercase ? { textTransform: 'lowercase' } : {};
    switch (true) {
      case this.props.value !== undefined:
        return (
          <React.Fragment>
            <input
              ref={this.inputRef}
              style={style}
              maxLength={this.props.maxLength ? Number.parseInt(this.props.maxLength) : 100}
              type={this.props.type}
              value={this.props.value}
              name={this.props.name}
              onChange={this.handleOnChange}
              required={this.props.required}
              pattern={this.props.pattern}
              {...relevant}
            />
            {this.state.errorMessage && (
              <span className="error">
                {_.isFunction(this.state.errorMessage) ? this.state.errorMessage() : this.state.errorMessage}
              </span>
            )}
            {this.state.infoMessage && !this.state.errorMessage && (
              <span className="info">
                {_.isFunction(this.state.infoMessage) ? this.state.infoMessage() : this.state.infoMessage}
              </span>
            )}
          </React.Fragment>
        );
      case this.props.defaultValue !== undefined:
        return (
          <React.Fragment>
            <input
              ref={this.inputRef}
              style={style}
              maxLength={this.props.maxLength ? Number.parseInt(this.props.maxLength) : 100}
              type={this.props.type}
              defaultValue={this.props.defaultValue}
              name={this.props.name}
              onChange={this.handleOnChange}
              required={this.props.required}
              pattern={this.props.pattern}
              {...relevant}
            />
            {this.state.errorMessage && (
              <span className="error">
                {_.isFunction(this.state.errorMessage) ? this.state.errorMessage() : this.state.errorMessage}
              </span>
            )}
            {this.state.infoMessage && !this.state.errorMessage && (
              <span className="info">
                {_.isFunction(this.state.infoMessage) ? this.state.infoMessage() : this.state.infoMessage}
              </span>
            )}
          </React.Fragment>
        );
      default:
        return (
          <React.Fragment>
            <input
              ref={this.inputRef}
              style={style}
              pattern={this.props.pattern}
              maxLength={this.props.maxLength ? Number.parseInt(this.props.maxLength) : 100}
              type={this.props.type}
              name={this.props.name}
              onChange={this.handleOnChange}
              required={this.props.required}
              {...relevant}
            />
            {this.state.errorMessage && (
              <span className="error">
                {_.isFunction(this.state.errorMessage) ? this.state.errorMessage() : this.state.errorMessage}
              </span>
            )}
            {this.state.infoMessage && !this.state.errorMessage && (
              <span className="info">
                {_.isFunction(this.state.infoMessage) ? this.state.infoMessage() : this.state.infoMessage}
              </span>
            )}
          </React.Fragment>
        );
    }
  }
  render() {
    return (
      <div ref={this.myRef} style={{ paddingBottom: this.props.noPadding ? '0px' : '' }} className="input-container">
        <label htmlFor={this.props.name}>
          {_.isFunction(this.props.label) ? (
            <React.Fragment>
              {this.props.required ? (
                <React.Fragment>
                  <span>
                    <b> * </b>
                  </span>
                  {this.props.label()}
                </React.Fragment>
              ) : (
                <React.Fragment>{this.props.label()}</React.Fragment>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>{this.props.required ? '*' + this.props.label : this.props.label}</React.Fragment>
          )}
        </label>
        {this.props.note &&
          (_.isFunction(this.props.note) ? (
            this.props.note()
          ) : (
            <div>
              <p className="input-note" />
            </div>
          ))}

        {this.props.initialLoad !== undefined && this.props.initialLoad && (
          <React.Fragment>{this.renderPlaceholder()}</React.Fragment>
        )}

        {(this.props.initialLoad === undefined || this.props.initialLoad === false) && (
          <React.Fragment>{this.renderInput()}</React.Fragment>
        )}
        {this.state.showCapsPopup && (
          <div className="caps-container">
            <span className="caps-popup">CapsLock is on.</span>
          </div>
        )}
      </div>
    );
  }
}
