import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Downshift from 'downshift';
import _ from 'lodash';
import './autocomplete.scss';

export default class Autocomplete extends Component {
  myRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      options: [],
      selectedItem: null,
      lastInputValue: '',
      errorMessage: '',
    };
  }

  scrollIntoView = () => {
    this.myRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
  };

  getData = () => {
    return this.state.selectedItem !== null
      ? this.state.selectedItem
      : this.props.defaultValue
      ? this.props.defaultValue
      : '';
  };

  setError = (errorMessage, dontScrollIntoView) => {
    this.setState({ errorMessage: errorMessage });
    if (!dontScrollIntoView) {
      this.scrollIntoView();
    }
  };

  handleStateChange = changes => {
    if (changes.hasOwnProperty('selectedItem')) {
      this.setState({ selectedItem: changes.selectedItem });
    } else if (changes.hasOwnProperty('inputValue')) {
      this.setState({ selectedItem: changes.inputValue });
    }
  };

  inputOnChange = event => {
    if (!event.target.value) {
      return;
    }

    this.dataSource(event.target.value);
  };

  downshiftOnChange(selectedItem) {
    console.log('autocomplete item', selectedItem);
    if (this.props.onChange) {
      this.props.onChange(selectedItem);
    } else {
      this.setState({ selectedItem: selectedItem });
    }
  }

  dataSource = async inputValue => {
    let currentValue = inputValue;

    if (this.state.lastInputValue.length > 0 && inputValue.includes(this.state.lastInputValue)) {
      this.setState({ lastInputValue: inputValue });
      this.setState({
        options: this.state.options.filter(
          item =>
            !inputValue ||
            (_.isFunction(this.props.filterField) ? this.props.filterField(item) : _.get(item, this.props.filterField))
              .toLowerCase()
              .includes(inputValue.toLowerCase())
        ),
      });
      return;
    }
    if (_.isFunction(this.props.dataSource)) {
      let { data, error } = await this.props.dataSource(inputValue);
      if (data) {
        this.setState({ options: data, lastInputValue: currentValue });
        return;
      }
      throw error;
    } else {
      this.setState({ options: this.props.dataSource });
    }
  };

  showAsString = item => {
    if (!_.isObject(item)) {
      return item;
    } else {
      return item
        ? _.isFunction(this.props.filterField)
          ? this.props.filterField(item)
          : _.get(item, this.props.filterField)
        : '';
    }
  };

  renderPlaceholder() {
    return <div className="placeholder"></div>;
  }

  render() {
    const value =
      this.state.selectedItem !== null
        ? this.state.selectedItem
        : this.props.defaultValue
        ? this.props.defaultValue
        : '';

    return (
      <React.Fragment>
        {this.props.initialLoad !== undefined && this.props.initialLoad && (
          <React.Fragment>{this.renderPlaceholder()}</React.Fragment>
        )}

        {(this.props.initialLoad === undefined || this.props.initialLoad === false) && (
          <div ref={this.myRef} className="input-container autocomplete-container">
            <Downshift itemToString={this.showAsString} selectedItem={value} onStateChange={this.handleStateChange}>
              {({
                getLabelProps,
                getInputProps,
                getToggleButtonProps,
                getMenuProps,
                getItemProps,
                isOpen,
                clearSelection,
                selectedItem,
                inputValue,
                highlightedIndex,
              }) => (
                <div style={{ margin: 'auto' }}>
                  {_.isFunction(this.props.label) ? (
                    this.props.label()
                  ) : (
                    <label style={{ marginTop: '1rem', display: 'block' }} {...getLabelProps()}>
                      {this.props.label}
                    </label>
                  )}
                  {this.props.note &&
                    (_.isFunction(this.props.note) ? (
                      this.props.note()
                    ) : (
                      <div>
                        <p className="input-note" />
                      </div>
                    ))}
                  {/* <div style={{ position: 'relative' }}> */}
                  <input
                    disabled={this.props.disabled}
                    {...getInputProps({
                      onChange: this.inputOnChange,
                      placeholder: 'Start typing and try matching, if applicable.',
                      maxLength: this.props.maxLength,
                    })}
                  />
                  {/* </div> */}
                  {isOpen && (
                    <div style={{ position: 'relative' }}>
                      <div {...getMenuProps({ isOpen })} className="downshift-dropdown">
                        {this.state.options &&
                          this.state.options
                            .filter(
                              item =>
                                !inputValue ||
                                (_.isFunction(this.props.filterField)
                                  ? this.props.filterField(item)
                                  : _.get(item, this.props.filterField)
                                )
                                  .toLowerCase()
                                  .includes(inputValue.toLowerCase())
                            )
                            .slice(0, 10)
                            .map((item, index) => (
                              <div
                                className="dropdown-item"
                                {...getItemProps({ key: index, index, item })}
                                style={{
                                  backgroundColor: highlightedIndex === index ? 'lightgray' : 'white',
                                  fontWeight: selectedItem === item ? 'bold' : 'normal',
                                }}
                              >
                                {this.props.displayField
                                  ? _.isFunction(this.props.displayField)
                                    ? this.props.displayField()
                                    : _.get(item, this.props.displayField)
                                  : item.name}
                              </div>
                            ))}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </Downshift>
            {this.state.errorMessage && <span className="error">{this.state.errorMessage}</span>}
          </div>
        )}
      </React.Fragment>
    );
  }

  // render() {
  //   const value = this.state.selectedItem;
  //   return (
  //     <div className="input-container autocomplete-container">
  //       <Downshift
  //         selectedItem={this.state.selectedItem}
  //         onStateChange={this.handleStateChange}
  //         // onChange={this.downshiftOnChange}
  //         itemToString={item => (this.props.itemToString ? this.props.itemToString(item) : item ? item.name : '')}
  //       >
  //         {({ selectedItem, getInputProps, getItemProps, highlightedIndex, isOpen, inputValue, getLabelProps }) => (
  //           <div>
  //             {_.isFunction(this.props.label) ? (
  //               this.props.label()
  //             ) : (
  //               <label style={{ marginTop: '1rem', display: 'block' }} {...getLabelProps()}>
  //                 {this.props.label}
  //               </label>
  //             )}
  //             {this.props.note &&
  //               (_.isFunction(this.props.note) ? (
  //                 this.props.note()
  //               ) : (
  //                 <div>
  //                   <p className="input-note" />
  //                 </div>
  //               ))}
  //             <br />
  //             <input
  //               {...getInputProps({
  //                 isOpen,
  //                 placeholder: this.props.placeholder ? this.props.placeholder : '...',
  //                 value
  //                 // onChange: this.inputOnChange,
  //               })}
  //             />
  //             {isOpen ? (
  //               <div className="downshift-dropdown">
  //                 {this.state.options &&
  //                   this.state.options
  //                     .filter(item => !inputValue || item.name.toLowerCase().includes(inputValue.toLowerCase()))
  //                     .slice(0, 10)
  //                     .map((item, index) => (
  //                       <div
  //                         className="dropdown-item"
  //                         {...getItemProps({ key: index, index, item })}
  //                         style={{
  //                           backgroundColor: highlightedIndex === index ? 'lightgray' : 'white',
  //                           fontWeight: selectedItem === item ? 'bold' : 'normal',
  //                         }}
  //                       >
  //                         {this.props.displayField
  //                           ? _.isFunction(this.props.displayField)
  //                             ? this.props.displayField()
  //                             : _.get(item, this.props.displayField)
  //                           : item.name}
  //                       </div>
  //                     ))}
  //               </div>
  //             ) : null}
  //           </div>
  //         )}
  //       </Downshift>
  //     </div>
  //   );
  // }
}
