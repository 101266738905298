import Request from '../logic/http/request';

export default class CandidatesHandler {
  static getCandidates = async params => {
    let { data, error } = await Request.get({
      url:  Request.requestMapping.candidate + '/full',
      params: params,
      //     [Request.requestMapping.client.get.queryParams.limit]: '',
      //     [Request.requestMapping.client.get.queryParams.offset]: '',
      //   },
    });
    return { data, error };
  };

  static getSingleUser = async id => {
    let { data, error } = await Request.get({
      url: Request.requestMapping.user.get.endpoint + `/${id}`,
      //     [Request.requestMapping.client.get.queryParams.limit]: '',
      //     [Request.requestMapping.client.get.queryParams.offset]: '',
      //   },
    });
    return { data, error };
  };

  static setBonusPaid = async body => {
    let { data, error } = await Request.post({
      url: Request.requestMapping.user.get.endpoint,
      data: body,
    });
    return { data, error };
  };


  static saveUser = async body => {
    let { data, error } = await Request.post({
      url: Request.requestMapping.user.get.endpoint,
      data: body,
    });
    return { data, error };
  };

  static changePassword = async body => {
    let { data, error } = await Request.patch({
      url: Request.requestMapping.user.get.endpoint,
      data: body,
    });
    return { data, error };
  };
}
