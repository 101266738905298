import React, { createContext } from 'react';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import Request from '../http/request';
export const UserContext = createContext({
  userName: '',
  personalInfo: '',
  loggedIn: false,
  userID: -1,
});

export class RoleProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: '',
      userEmail: '',
      userID: -1,
      getEmail: this.getEmail,
      updateEmail: this.updateEmail,
      getUserID: this.getUserID,
      updateUserId: this.updateUserId,
      updateRole: this.updateRole,
      updateName: this.updateName,
      setToken: this.setToken,
      logout: this.logout,
    };
    this.tokenPromise = this.getUserData();
  }

  logout = async () => {
    this.setState({
      userName: '',
      userID: -1,
      loggedIn: false,
    });
    Cookies.remove('th-userinfo');
  };
  async componentDidMount() {
    let ThCookie = await this.tokenPromise;
    this.setToken(ThCookie);
  }

  getUserID = async () => {
    if (this.state.userID === -1) {
      let token = await this.tokenPromise;
      if (token) {
        var decoded = jwt_decode(token);

        let userObject;
        if (decoded.sub) {
          userObject = JSON.parse(decoded.sub);
          if (userObject) {
            return userObject.user.id;
          }
        }
      }
    }
    return this.state.userID;
  };

  setToken = token => {
    if (token) {
      var decoded = jwt_decode(token);

      let userObject;
      if (decoded.sub) {
        userObject = JSON.parse(decoded.sub);
      }
      if (decoded.rol) {
        let role = decoded.rol;
        if (role !== 'ROLE_ADMIN') {
          return;
        }
      } else {
        return;
      }

      if (userObject) {
        this.updateEmail(userObject.user.username);
        this.updateName(
          userObject.personalInfo
            ? `${userObject.personalInfo.firstName} ${userObject.personalInfo.lastName}`
            : userObject.user.username
        );
        this.updateUserId(userObject.user.id);
      }
      this.setState({ loggedIn: true });
      Request.setAuth(token);
    }
  };

  getUserData() {
    return Cookies.get('th-userinfo');
  }

  async setUserData() {
    Cookies.set('ack-login', 'value');
  }

  updateUserId = userId => {
    this.setState({ userID: userId });
  };

  updateRole = userRole => {
    this.setState({ role: userRole });
  };
  getEmail = async () => {
    let token = await this.tokenPromise;
    if (token) {
      var decoded = jwt_decode(token);

      let userObject;
      if (decoded.sub) {
        userObject = JSON.parse(decoded.sub);
        if (userObject) {
          return userObject.user.username;
        }
      }
    }
  };

  updateEmail = userEmail => {
    this.setState({ userEmail: userEmail });
  };

  updateName = userName => {
    this.setState({ userName: userName });
  };

  render() {
    return <UserContext.Provider value={this.state}>{this.props.children}</UserContext.Provider>;
  }
}

export function withRole(Component) {
  return function UserAwareComponent(props) {
    const { forwardedRef, ...rest } = props;

    return (
      <UserContext.Consumer>{context => <Component {...context} ref={forwardedRef} {...rest} />}</UserContext.Consumer>
    );
  };

  // return React.forwardRef((props, ref) => {
  //   return <UserAwareComponent {...props} forwardedRef={ref} />;
  // });
}
