import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { SnackbarProvider } from 'notistack';
import { RoleProvider } from './shared/logic/context-providers/role-provider';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import Cookies from 'js-cookie';
import * as serviceWorker from './serviceWorker';
import LoginPage from './pages/login/index';
import Request from './shared/logic/http/request';
let jwtdec = null;

export const AuthHelper = {
  isAuthenticated: props => {
    if (jwtdec || Request.getAuth()) {
      return true;
    } else {
      let thCookie = Cookies.get('th-userinfo');
      if (!thCookie) {
        return false;
      }

      jwtdec = jwt_decode(thCookie);
      return true;
    }
  },

  signout(cb) {
    this.isAuthenticated = false;
    setTimeout(cb, 100);
  },
};

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => {
        return AuthHelper.isAuthenticated(rest) ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
}

ReactDOM.render(
  <Router>
    {/* <AlertsProvider> */}
    <RoleProvider>
      <SnackbarProvider maxSnack={5}>
        <Switch>
          <Route exact path="/login" component={() => <LoginPage />} />
          <PrivateRoute component={() => <App />} />
        </Switch>
      </SnackbarProvider>
    </RoleProvider>
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
